import {motion, useInView, useSpring} from 'framer-motion';
import type {ReactNode} from 'react';
import {useRef} from 'react';
type ViewProps = {
  children?: ReactNode;
  className?: string;
};
export const InView = ({children, className}: ViewProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true, margin: '0px 0px -100px 0px'});
  const x = useSpring(0);
  return (
    <motion.div
      ref={ref}
      initial={{y: 200, opacity: 0}}
      animate={{y: isInView ? 0 : 200, opacity: isInView ? 1 : 0}}
      transition={{duration: 1, ease: 'easeInOut'}}
      className={className}>
      {children}
    </motion.div>
  );
};
