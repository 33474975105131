import {LucideIcon} from 'lucide-react';
import type {Theme} from '../Theme';
import {cn} from '~/utils';

type FeatureCardProps = {
  Icon: LucideIcon;
  title: string;
  description: string;
  color?: Theme.Color;
};

export const FeatureCard = ({description, Icon, title, color = 'gray'}: FeatureCardProps) => {
  return (
    <div className={cn('text-primary space-y-3 max-w-sm')}>
      <div className={cn('bg-primary-foreground w-12 h-12 mx-auto rounded-full flex items-center p-2 justify-center')}>
        <Icon size={24} />
      </div>
      <h4 className="text-lg font-semibold">{title}</h4>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};
