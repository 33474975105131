import {ReactNode} from 'react';

import {cn} from '~/utils';

type CtaSectionProps = {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  secondary?: boolean;
  actions?: ReactNode;
  className?: string;
};

export const CtaSection = ({children, title, subtitle, className, secondary, actions}: CtaSectionProps) => {
  return (
    <section className={cn('pt-44 pb-24 min-h-screen', className)}>
      <div className={cn('mx-auto px-4 md:px-8 text-center max-w-screen-xl flex flex-col items-center')}>
        <h3
          className={cn('text-3xl font-semibold sm:text-4xl max-w-xl', secondary ? 'text-secondary' : 'text-primary')}>
          {title}
        </h3>
        {!!subtitle && <p className={cn('mt-3 max-w-xl text-gray-600')}>{subtitle}</p>}
        {actions}
        <div className="mt-32 flex sm:flex-row flex-col gap-8 flex-wrap w-full justify-evenly">{children}</div>
      </div>
    </section>
  );
};
