import {Compass, Globe, Mail, PlayCircle, Smartphone, Users} from 'lucide-react';
import {forwardRef, useState} from 'react';
import {CtaSection, FeatureCard} from '~/ui/Cta';
import {Badge} from '~/ui/badge';
import {ButtonLink} from '~/ui/button-link';
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from '~/ui/card';
import {InView} from '~/ui/in-view';
import {Slider} from '~/ui/slider';
import {View} from '~/ui/view';
import {cn} from '~/utils';
import {PageFooter} from '../$lang._public/PageFooter';
import {useDictionary} from '../$lang/route';

export default function Index() {
  const {t} = useDictionary();
  const [teamsCount, setTeamsCount] = useState([3]);
  const count = teamsCount[0];
  const priceAsNumber = count > 10 ? count * 0.5 : 4.5;
  const price = priceAsNumber.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR'});
  return (
    <>
      <View>
        <main className="flex-1">
          <CtaSection
            className="bg-primary/5"
            title={t(l => l.cta_title)}
            subtitle={t(l => l.cta_subtitle)}
            actions={
              <div className="flex flex-row mt-8 gap-4 py-4">
                <ButtonLink colored variant="secondary" icon={Compass} to="/signup">
                  {t(l => l.try)}
                </ButtonLink>
                <ButtonLink target="_blank" colored icon={PlayCircle} to="https://zcal.co/simon-boisset/questovery">
                  {t(l => l.ask_for_demo)}
                </ButtonLink>
              </div>
            }>
            <FeatureCard title={t(l => l.cta_feat_1_title)} description={t(l => l.cta_feat_1_subtitle)} Icon={Globe} />
            <FeatureCard
              title={t(l => l.cta_feat_2_title)}
              description={t(l => l.cta_feat_2_subtitle)}
              Icon={Compass}
            />
            <FeatureCard
              title={t(l => l.cta_feat_3_title)}
              description={t(l => l.cta_feat_3_subtitle)}
              Icon={Smartphone}
            />
          </CtaSection>
          <InView>
            <LateralScreenshots
              action={
                <ButtonLink colored icon={Compass} to="/signup">
                  {t(l => l.start)}
                </ButtonLink>
              }
              reverse
              src="https://questovery-files.s3.fr-par.scw.cloud/public/screen-step.png"
              title={t(l => l.cta_screen_1_title)}
              description={t(l => l.cta_screen_1_subtitle)}
            />
          </InView>
          <InView>
            <CtaSection
              className="bg-secondary/10"
              title={t(l => l.cta_price_title)}
              secondary
              actions={
                <div className="flex flex-row mt-8 gap-4 py-4">
                  <ButtonLink colored icon={Mail} to="mailto:contact@questovery.com">
                    {t(l => l.contact_us)}
                  </ButtonLink>
                  <ButtonLink colored variant="secondary" icon={Compass} to="/signup">
                    {t(l => l.start)}
                  </ButtonLink>
                </div>
              }
              subtitle={t(l => l.plan_questovery_description)}>
              <PrepaidTeamsSection count={count} />
              <Card className="flex-1">
                <CardHeader>
                  <CardTitle className="text-secondary">{t(l => l.plan_questovery)}</CardTitle>
                  <CardDescription>{t(l => l.subscription_plan_description)}</CardDescription>
                </CardHeader>
                <CardContent className="pt-8">
                  <p className="flex flex-row items-center w-full justify-center gap-2 mb-4">
                    <Badge variant="secondary">{price}</Badge>
                    <span> / {t(l => l.month.toLocaleLowerCase())}</span>
                  </p>
                  <p className="flex flex-row items-center w-full justify-center gap-2 mb-4">
                    {t(l => l.teams_count_during_the_month)}
                    <Badge className="ml-2">
                      {count}
                      <Users className="size-3 ml-1" />
                    </Badge>
                  </p>
                  <div className="py-8">
                    <Slider defaultValue={[0]} max={250} step={1} value={teamsCount} onValueChange={setTeamsCount} />
                  </div>
                  <div className="flex flex-row items-center w-full justify-center">
                    <ButtonLink colored variant="secondary" icon={Compass} to="/signup">
                      {t(l => l.start)}
                    </ButtonLink>
                  </div>
                </CardContent>
              </Card>
            </CtaSection>
          </InView>
        </main>
      </View>
      <PageFooter />
    </>
  );
}

type LateralScreenshotsProps = {
  className?: string;
  title: string;
  description: string;
  src: string;
  reverse?: boolean;
  action?: React.ReactNode;
};

const LateralScreenshots = forwardRef<any, LateralScreenshotsProps>(
  ({className, title, description, src, reverse, action}, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          className,
          'flex gap-12 items-start p-12 group flex-col',
          reverse ? 'sm:flex-row-reverse' : 'sm:flex-row ',
        )}>
        <div className="flex flex-col gap-4 flex-1">
          <div className="flex flex-col items-start gap-2">
            <h3 className="text-2xl font-bold text-primary">{title}</h3>
            <p className="flex-1 mb-8">{description}</p>
            {action}
          </div>
        </div>
        <img
          src={src}
          alt={title}
          className={cn(
            'sm:w-1/2 w-full group-hover:translate-x-0 ease-in-out duration-300 group-hover:scale-105 transition-transform shadow-md rounded-md object-cover',
            reverse ? 'sm:-translate-x-1/4' : 'sm:translate-x-1/4',
          )}
        />
      </div>
    );
  },
);

const PrepaidTeamsSection = ({count}: {count: number}) => {
  const {t} = useDictionary();
  return (
    <Card className="flex-1 flex flex-col">
      <CardHeader>
        <CardTitle className="text-primary">{t(l => l.prepaid_teams)}</CardTitle>
        <CardDescription>
          <p>{t(l => l.prepaid_team_price_description)}</p>
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-1 flex flex-col">
        <p className="text-xl font-bold flex-1 pt-8">
          <span className="text-primary">1€</span> / {t(l => l.team.toLowerCase())}
        </p>
        <div className="flex flex-row items-center w-full justify-center">
          <ButtonLink colored icon={Compass} to="/signup">
            {t(l => l.start)}
          </ButtonLink>
        </div>
      </CardContent>
    </Card>
  );
};
